import React from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/layout/main';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Underline from 'components/divider/underline';
import PageTitle from 'components/page/title';
import BlockImage from 'components/image/block';
import { getNodeFromQuery } from 'components/utility/data';
import useFlexStyles from 'components/flex/flex.css';

const DesignCoffeePage = (props) => {

    const images = props.data.allImages;
    const containerSize = 'md';

    const renderImage = (sourceName, height) => (
        <BlockImage height={height} fluid={getNodeFromQuery(images, sourceName).childImageSharp.fluid} />
    )

    const flexClass = useFlexStyles();

    return (
        <Layout navAttachSize={containerSize}>
            <Container maxWidth={containerSize}>
                <PageTitle navOffset>
                    {renderImage('book')}
                </PageTitle>
                <Box my={6}>
                    <Typography component="h1" variant="h4">
                        Coffee Zine
                    </Typography>
                    <p>
                    The first iteration of this zine for two dear friends and coffee fanatics. I had just left working at Colectivo Coffee and learned a few things about making a fantastic cup. The goal for this zine is to create a physical guide to coffee brewing and culture. 
                    </p>
                </Box>
                <Box mb={6}>
                    {renderImage('logo', 400)}
                </Box>
            </Container>
            <Box bgcolor="secondary.main">
                <Container maxWidth={containerSize}>
                    <Box pt={10}>
                        <Grid container spacing={6  }>
                            <Grid item xs={12} md={6}>
                                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="stretch" height="100%">
                                    <Typography component="h5" variant="h5">
                                        A great cup starts with a perfect brew
                                    </Typography>
                                    <p>
                                        The zine will feature in-depth brewing guides from some
                                        of the most popular methods from Pour-over to Drip.
                                    </p>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                {renderImage('drip-design', 360)}
                            </Grid>
                        </Grid>
                    </Box>
                    
                    <Underline spacing={6} width="100%" />

                    <Grid container>
                        <Grid item xs={12} md={6} className={flexClass.order_2m1}>
                            {renderImage('stool', 360)}
                        </Grid>
                        <Grid item xs={12} md={6} className={flexClass.order_1m2}>
                            {renderImage('ratio', 360)}
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            <Box my={20}>
                <Container maxWidth="sm">
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                {renderImage('g01')}
                            </Grid>
                            <Grid item xs={6}>
                                {renderImage('g02a')}
                            </Grid>
                            <Grid item xs={6}>
                                {renderImage('g02b')}
                            </Grid>
                        </Grid>
                </Container>
            </Box>
        </Layout>
    );
}

export const query = graphql`
    query {
        allImages: allFile(
            filter: {relativeDirectory: { eq: "design/coffee" }},
            sort: {fields: name, order: ASC}
        ) {
            edges {
                node {
                    id
                    name
                    childImageSharp {
                        resolutions {
                            aspectRatio
                        }
                        fluid(maxHeight: 1000) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }
`;

export default DesignCoffeePage;