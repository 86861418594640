import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
    order_1m2: () => ({
        order: 1,
        [theme.breakpoints.up('md')]: {
            order: 2
        }
    }),
    order_2m1: () => ({
        order: 2,
        [theme.breakpoints.up('md')]: {
            order: 1,
        }
    })
}));
